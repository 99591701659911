/**
 * Our definition-list-block
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Peritus
import SimpleLayout from '../../components/SimpleLayout';
import Block from 'components/Block';

// Styles
import * as styles from './definition-list.module.scss';
import {layout} from 'components/Block/block.module.scss';

const DefinitionList = ({definitionListContent, ...props}) => {
    return (
        <Block Parent={'dl'} className={styles.definitionList} {...props}>
            {
                definitionListContent && definitionListContent.map((definitionItem, index) => {
                    const {title, content} = definitionItem;

                    return (<React.Fragment key={index}>
                        <dt className={styles.title}>{title}</dt>
                        <dd className={styles.description}>
                            <SimpleLayout
                                blocks={content}
                                layoutClasses={classNames({[styles.content]: true, [layout]: true})}/>
                        </dd>
                    </React.Fragment>);
                })
            }
        </Block>
    );
};

DefinitionList.propTypes = {
    definitionListContent: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.arrayOf(PropTypes.object.isRequired),
    })).isRequired,
};

export default DefinitionList;
